<template>
    <div ref="container">
        <v-card :loading="loading" class="pa-2" height="100vh">
            <v-card-title ref="title">
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    {{
                        invoice.status == 'credit' || invoice.status == 'billed'
                            ? 'Credit Note Request'
                            : 'Invoice Request'
                    }}
                    {{ invoice.code }} /
                    <span class="font-weight-black text-uppercase">
                        PROJECT: {{ project.name }}</span
                    >
                </h2>
                <v-btn
                    v-if="invoice.rejectionReasons"
                    small
                    icon
                    @click="rejectionTable = true"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text
                id="virtual-scroll-table"
                class="px-4"
                :style="`height: ${height}px`"
            >
                <v-row ref="details_1">
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            v-if="invoice.createdBy && invoice.createdBy.name"
                            v-model="invoice.createdBy.name"
                            outline
                            prefix="Created By: "
                            hide-details
                            prepend-icon="mdi-account-group-outline"
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            hide-details
                            v-model="invoice.date"
                            prepend-icon="mdi-calendar"
                            prefix="Created On: "
                            outline
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            v-model="invoice.client.name"
                            outline
                            prefix="Client: "
                            hide-details
                            prepend-icon="mdi-office-building-outline"
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            v-if="invoiceConcept"
                            outline
                            v-model="invoiceConcept.name"
                            prepend-icon="mdi-table-column"
                            prefix="Concept: "
                            hide-details
                            readonly
                        />
                    </v-col>
                </v-row>
                <v-row ref="details_2">
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            hide-details
                            v-model="project.currency"
                            prepend-icon="mdi-cash"
                            prefix="Currency: "
                            outline
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            outline
                            v-model="invoice.region"
                            prepend-icon="mdi-earth"
                            prefix="Region: "
                            hide-details
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            hide-details
                            v-model="invoice.notes"
                            prepend-icon="mdi-text"
                            prefix="Notes: "
                            outline
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            outline
                            style="color:blue;"
                            v-model="invoice.invoiceNumber"
                            prepend-icon="mdi-receipt-text-outline"
                            prefix="Invoice No: "
                            readonly
                        />
                    </v-col>
                </v-row>
                <v-row ref="details_3">
                    <v-row
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        class="pa-2 pt-0"
                        v-if="invoice.status == 'credit' && !disabled"
                    >
                        <v-file-input
                            prefix="Upload Credit Note"
                            v-model="credit"
                            :rules="[rules.required]"
                            required
                            prepend-icon="mdi-upload"
                            accept=".pdf"
                            style="color:blue; width: 10px;"
                            :clearable="false"
                        ></v-file-input>
                        <v-col cols="9">
                            <v-chip
                                v-if="invoice.credit"
                                class="mt-1 ml-1"
                                @click="loadFile(invoice.credit)"
                            >
                                <v-icon class="mr-1">mdi-paperclip</v-icon>
                                <span>{{ invoice.credit }}</span>
                            </v-chip>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="invoice.status == 'closed'"
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        class="d-flex flex-wrap pa-6 pt-0"
                    >
                        <div class="d-flex mr-2" v-if="invoice.attached">
                            <h3 class="mt-5">Invoice:</h3>
                            <v-chip
                                class="mt-4 ml-1 pr-10"
                                style="max-width: 200px"
                                @click="loadFile(invoice.attached)"
                            >
                                <v-icon>mdi-paperclip</v-icon>
                                <span class="col-12 text-truncate">{{
                                    invoice.attached
                                }}</span>
                            </v-chip>
                        </div>
                        <div class="d-flex" v-if="creditFiles.length > 0">
                            <h3 class="mt-5">Credit notes:</h3>
                            <v-chip
                                class="mt-4 ml-1"
                                v-for="(file, i) in creditFiles"
                                :key="i"
                                @click="loadFile(file)"
                                style="max-width: 200px"
                            >
                                <v-icon class="mr-1">mdi-paperclip</v-icon>
                                <span>{{ file }}</span>
                            </v-chip>
                        </div>

                        <div class="d-flex" v-if="invoice.attachments">
                            <h3 class="ml-2 mt-5">Attachments:</h3>
                            <v-chip
                                class="mt-4 ml-1 pr-10"
                                style="max-width: 200px"
                                v-for="(file, i) in invoice.attachments"
                                :key="'attachment_' + i"
                            >
                                <v-icon>mdi-paperclip</v-icon>
                                <span @click="openFileAttached(file)">{{
                                    file
                                }}</span>
                            </v-chip>
                        </div>
                    </v-row>
                </v-row>
                <v-row v-if="validateConcept" no-gutters>
                    <v-col cols="12" ls="3" md="3" sm="3">
                        <div class="d-flex justity-center">
                            <v-text-field
                                type="number"
                                hide-details
                                v-model="invoice.amount"
                                prepend-icon="mdi-text"
                                prefix="Amount: "
                                outline
                                readonly
                                style="max-width: 180px;"
                            />
                            <p class="font-weight-black mr-4  my-5">
                                {{
                                    invoiceTotalCredit() > 0
                                        ? `(-${formatNumber(
                                              invoiceTotalCredit()
                                          )})`
                                        : ''
                                }}
                            </p>
                            <v-spacer />
                        </div>
                    </v-col>
                    <v-col cols="12" ls="3" md="3" sm="3">
                        <v-text-field
                            v-if="
                                (invoice.status == 'credit' ||
                                    invoice.status == 'billed') &&
                                    !disabled
                            "
                            v-model="creditTotalPrice"
                            prefix="Credit: $"
                            dense
                            class="mr-4 ml-3 my-5"
                            style="max-width: 250px;"
                            @input="changeValue = false"
                            :rules="[validCredit()]"
                            type="number"
                        ></v-text-field>
                        <p class="font-weight-black mx-4 my-5" v-else>
                            Credit:
                            {{ formatNumber2(creditNoteTotalPrice()) }}
                        </p>
                    </v-col>
                    <v-col cols="12" ls="3" md="3" sm="3">
                        <p class="font-weight-black mr-4  my-5">
                            Total:
                            {{
                                formatNumber(
                                    invoice.amount -
                                        creditTotalPrice -
                                        (invoice.status == 'closed'
                                            ? creditNoteTotalPrice()
                                            : invoiceTotalCredit())
                                )
                            }}
                        </p>
                    </v-col>
                </v-row>
                <v-row
                    v-if="!validateConcept"
                    no-gutters
                    class="quote_tables mt-3"
                >
                    <v-col
                        cols="12"
                        v-for="quoteId of Object.keys(invoice.items)"
                        :key="quoteId"
                        class="mb-6"
                    >
                        <v-data-table
                            v-if="validateInvoiceItems(invoice.items[quoteId])"
                            :headers="headers"
                            :items="invoice.items[quoteId]"
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    style="background-color: #eeeeee"
                                    class="mt-0 mx-0"
                                >
                                    <v-col cols="4" class="d-flex">
                                        <h2 class="mt-1">
                                            QUOTE:
                                            {{
                                                quoteId == 'free'
                                                    ? 'free'
                                                    : getQuoteName(quoteId)
                                            }}
                                        </h2>
                                    </v-col>
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.index`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{ item.name }}
                                </p>
                            </template>
                            <template v-slot:[`item.pricePerUnit`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{ formatNumber(item.pricePerUnit) }}
                                </p>
                            </template>
                            <template v-slot:[`item.quantity`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{ item.creditNoteQty || item.billedQty }}
                                    {{
                                        item.creditQty &&
                                        invoice.status != 'closed'
                                            ? `(-${item.creditQty})`
                                            : ''
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.creditQuantity`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-text-field
                                        v-if="
                                            (invoice.status == 'credit' ||
                                                invoice.status == 'billed') &&
                                                !disabled
                                        "
                                        type="number"
                                        v-model="item.creditQtyRequested"
                                        style="max-width: 50px"
                                        :rules="[rules.number, maxValue(item)]"
                                        @input="changeValue = true"
                                    />
                                    <p class="my-0 font-weight-bold" v-else>
                                        {{
                                            invoice.status == 'closed'
                                                ? item.creditQty
                                                : invoice.creditPrice ||
                                                  item.creditQtyRequested
                                                ? item.creditQtyRequested
                                                : item.creditNoteQty
                                        }}
                                    </p>
                                </div>
                            </template>
                            <template v-slot:[`item.currentInvoice`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{
                                        formatNumber(
                                            (item.creditNoteQty ||
                                                item.billedQty ||
                                                0) * item.pricePerUnit
                                        )
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.currentCredit`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{
                                        formatNumber(
                                            ((invoice.status == 'closed'
                                                ? item.creditQty
                                                : invoice.creditPrice ||
                                                  item.creditQtyRequested
                                                ? item.creditQtyRequested
                                                : item.creditNoteQty) || 0) *
                                                item.pricePerUnit
                                        )
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.total`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{
                                        formatNumber(
                                            Number(
                                                (item.creditNoteQty ||
                                                    item.billedQty ||
                                                    0) * item.pricePerUnit
                                            ) -
                                                Number(
                                                    ((invoice.status == 'closed'
                                                        ? item.creditQty
                                                        : invoice.creditPrice ||
                                                          item.creditQtyRequested
                                                        ? item.creditQtyRequested
                                                        : item.creditNoteQty) ||
                                                        0) * item.pricePerUnit
                                                )
                                        )
                                    }}
                                </p>
                            </template>
                            <!--FOOTER-->
                            <template v-slot:[`body.append`]>
                                <tr style="background-color: #eeeeee">
                                    <td class="font-weight-bold"></td>
                                    <td />
                                    <td class="font-weight-bold"></td>
                                    <td class="font-weight-bold"></td>
                                    <td class="font-weight-bold text-center">
                                        {{
                                            quoteTotalQty(
                                                invoice.items[quoteId]
                                            )
                                        }}
                                    </td>
                                    <td class="font-weight-bold text-center">
                                        {{
                                            creditTotalQty(
                                                invoice.items[quoteId]
                                            )
                                        }}
                                    </td>
                                    <td class="font-weight-bold text-center">
                                        {{
                                            formatNumber(
                                                quoteTotalInvoice(
                                                    invoice.items[quoteId]
                                                )
                                            )
                                        }}
                                    </td>
                                    <td class="font-weight-bold text-center">
                                        {{
                                            formatNumber(
                                                quoteTotalCredit(
                                                    invoice.items[quoteId]
                                                )
                                            )
                                        }}
                                    </td>
                                    <td class="font-weight-bold text-center">
                                        {{
                                            formatNumber(
                                                quoteTotal(
                                                    invoice.items[quoteId]
                                                )
                                            )
                                        }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row
                    v-if="!validateConcept"
                    no-gutters
                    style="background-color: #eeeeee"
                    class="mt-8"
                    ref="totals"
                >
                    <v-col cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black ml-4">TOTALS:</p>
                    </v-col>
                    <v-col v-if="!inProcess" cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black">
                            PROJECT PRICE: {{ formatNumber(project.price) }}
                        </p>
                    </v-col>
                    <v-col v-if="!inProcess" cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black">
                            PROJECT BILLED: {{ formatNumber(projectBilled()) }}
                        </p>
                    </v-col>
                    <v-spacer />
                    <v-col
                        cols="12"
                        xl="5"
                        lg="5"
                        md="5"
                        class="d-flex justify-space-between"
                    >
                        <p class="my-2 font-weight-black mr-4">
                            Invoiced:
                            {{ formatNumber2(invoiceTotalPrice()) }}
                            {{
                                invoiceTotalCredit() > 0
                                    ? `(-${formatNumber(invoiceTotalCredit())})`
                                    : ''
                            }}
                        </p>
                        <v-text-field
                            v-if="
                                (invoice.status == 'credit' ||
                                    invoice.status == 'billed') &&
                                    !disabled
                            "
                            v-model="creditTotalPrice"
                            prefix="Credit: $"
                            dense
                            class="mr-4 ml-3"
                            style="max-width: 250px;"
                            @input="changeValue = false"
                            :rules="[validCredit()]"
                            type="number"
                        ></v-text-field>
                        <p class="my-2 font-weight-black mr-4" v-else>
                            Credit:
                            {{ formatNumber2(creditNoteTotalPrice()) }}
                        </p>

                        <p class="my-2 font-weight-black mr-4">
                            Total:
                            {{ formatNumber(invoiceTotal()) }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions ref="actions">
                <v-spacer />
                <v-btn
                    v-if="
                        (invoice.status == 'credit' ||
                            invoice.status == 'billed') &&
                            !disabled
                    "
                    :disabled="
                        (this.invoice.status == 'credit' &&
                            !this.invoice.credit) ||
                            (!validCredit() && !validateConcept)
                    "
                    rounded
                    color="red"
                    class="mb-4 mr-2 white--text"
                    @click="creditAction()"
                >
                    {{
                        invoice.status == 'billed'
                            ? 'REQUEST CREDIT NOTE'
                            : 'GENERATE CREDIT NOTE'
                    }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--CLOSE INVOICE-->
        <v-dialog
            v-model="openCloseInvoice"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openCloseInvoice">
                <v-card-title class="text-h5"
                    >GENERATE CREDIT NOTE</v-card-title
                >
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            When generating this credit note, it will change to
                            closed status, are you sure you want to continue?
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="openCloseInvoice = false"
                    >
                        CANCEL
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="generateCreditNote"
                        color="primary"
                        :loading="loading"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Rejected-->
        <v-dialog persistent max-width="600px" v-model="rejectionTable">
            <RejectedInvoicesTable
                v-if="rejectionTable"
                :items="invoice.rejectionReasons"
                @closeDialog="rejectionTable = false"
            />
        </v-dialog>
        <!--Request Credit Note-->
        <v-dialog
            v-model="creditReasonForm"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="creditReasonForm">
                <v-card-title class="text-h5">Reasons For Request</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you are requesting the credit
                            note.
                        </v-col>
                    </v-row>
                    <v-form v-model="valid">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="creditReasonForm = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="requestCreditNote"
                        color="primary"
                        :loading="loading"
                        :disabled="!valid"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Error Alert-->
        <Errors />
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import API from '@/services/api'
import { openFile } from '@/services/storage/openFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import { loadImage } from '@/helpers/imageHandler.js'

export default {
    name: 'CreditNoteRequest',
    props: {
        project: {
            type: Object,
            required: true,
        },
        invoice: {
            type: Object,
            required: true,
        },
        inProcess: {
            type: Boolean,
            default: () => false,
        },
        setting: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
        RejectedInvoicesTable: () =>
            import('@/components/Invoicing/RejectedInvoicesTable'),
    },
    data: () => ({
        openCloseInvoice: false,
        loading: true,
        rules: {
            required: v => !!v || 'The value is required',
            number: v => v == '' || v > 0 || 'Not valid',
        },
        concepts: [],
        height: 0,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ITEM',
                value: 'description',
                sortable: false,
            },
            {
                text: 'UNIT PRICE',
                value: 'pricePerUnit',
                sortable: false,
            },
            {
                text: 'CATEGORY',
                value: 'category',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                sortable: false,
                align: 'center',
            },
            {
                text: 'CREDIT QTY',
                value: 'creditQuantity',
                sortable: false,
                align: 'center',
            },
            {
                text: 'INVOICED',
                value: 'currentInvoice',
                sortable: false,
                align: 'center',
            },
            {
                text: 'CREDIT',
                value: 'currentCredit',
                sortable: false,
                align: 'center',
            },
            {
                text: 'TOTAL',
                value: 'total',
                sortable: false,
                align: 'center',
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        credit: null,
        rejectionTable: false,
        invoiceConcept: null,
        creditTotalPrice: 0,
        changeValue: true,
        creditReasonForm: false,
        valid: false,
        rejectedNotes: null,
        creditFiles: [],
        totalCredit: null,
    }),
    computed: {
        validateConcept() {
            if (this.invoice.concept) {
                const concept = this.getConcept(this.invoice.concept)
                if (concept) {
                    return concept.enabledValue
                }
            }
            return false
        },
        totalFooter() {
            return this.creditTotalPrice || this.invoice.creditPrice
                ? this.invoiceTotalPrice() -
                      (this.creditTotalPrice || this.invoice.creditPrice)
                : this.invoiceTotalPrice()
        },
    },
    mounted() {
        try {
            this.loading = true
            this.concepts = this.setting.concepts
            this.invoiceConcept = this.concepts.find(
                c => c.id == this.invoice.concept
            )
            this.invoice.date = this.formatDate(this.invoice.createdOn._seconds)
            if (this.invoice.creditPrice) {
                this.changeValue = false
                this.creditTotalPrice = this.invoice.creditPrice
            }
            this.onResize()
            if (this.invoice.status == 'closed') {
                if (this.project.invoices) {
                    const index = this.project.invoices.findIndex(
                        i => i.id == this.invoice.id
                    )

                    if (
                        index > -1 &&
                        this.project.invoices[index].creditNotes
                    ) {
                        this.creditFiles = this.project.invoices[
                            index
                        ].creditNotes
                            .filter(i => i.credit)
                            .map(i => i.credit)

                        this.totalCredit = this.project.invoices[
                            index
                        ].creditNotes.reduce(
                            (accumulator, creditNote) =>
                                accumulator + creditNote.creditPrice,
                            0
                        )
                    }
                }

                if (this.invoice.credit) {
                    this.creditFiles.push(this.invoice.credit)
                }
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    watch: {
        async credit() {
            try {
                this.loading = true
                await saveFile(
                    this.credit,
                    `${this.companyId}/projects/${this.project.id}/invoices`
                )
                this.invoice.credit = this.credit.name
                const invoice = await API.uploadCreditNote({
                    id: this.invoice.id,
                    projectId: this.project.id,
                    credit: this.invoice.credit,
                })
                this.$emit('replaceInvoice', invoice)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        getConcept(id) {
            return this.concepts.find(c => c.id == id)
        },
        async generateCreditNote() {
            try {
                this.loading = true
                let requests = {}
                for (const quoteId of Object.keys(this.invoice.items)) {
                    if (this.invoice.items[quoteId].length > 0) {
                        let itemsRequested = this.invoice.items[quoteId].filter(
                            item =>
                                item.creditQtyRequested &&
                                item.creditQtyRequested > 0
                        )
                        if (itemsRequested.length > 0) {
                            requests[quoteId] = itemsRequested
                        }
                    }
                }

                let conceptType = true
                if (this.invoice.concept) {
                    const concept = this.getConcept(this.invoice.concept)
                    if (concept) {
                        conceptType = concept.enabledValue
                    }
                }

                const invoice = await API.generateCreditNote({
                    projectId: this.invoice.projectId,
                    id: this.invoice.id,
                    creditPrice: this.creditTotalPrice,
                    creditRequests: requests,
                    conceptType,
                })
                this.$emit('replaceInvoice', invoice)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async loadFile(file) {
            try {
                this.loading = true
                var url = await loadImage(
                    `${this.companyId}/projects/${this.invoice.projectId}/invoices`,
                    file
                )
                if (url) {
                    setTimeout(() => {
                        window.open(url, '_blank')
                        this.loading = false
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        invoiceTotal() {
            const invoicePrice = this.invoiceTotalPrice()
            const previousCredit = this.invoiceTotalCredit()
            const creditPrice = this.creditNoteTotalPrice()
            if (this.invoice.status != 'closed') {
                return invoicePrice - this.creditTotalPrice - previousCredit
            } else {
                return invoicePrice - creditPrice - previousCredit
            }
        },
        invoiceTotalPrice() {
            let total = 0
            for (const quoteId of Object.keys(this.invoice.items)) {
                for (const cost of this.invoice.items[quoteId]) {
                    total +=
                        (cost.creditNoteQty || cost.billedQty || 0) *
                        cost.pricePerUnit
                }
            }
            return total
        },
        creditNoteTotalPrice() {
            let total = 0
            if (this.invoice.totalCredit && this.invoice.status == 'closed') {
                total = this.invoice.totalCredit
            } else if (this.creditTotalPrice || this.invoice.creditPrice) {
                total = this.creditTotalPrice || this.invoice.creditPrice
            } else {
                for (const quoteId of Object.keys(this.invoice.items)) {
                    for (const cost of this.invoice.items[quoteId]) {
                        total +=
                            ((this.invoice.status == 'closed'
                                ? cost.creditQty
                                : this.invoice.creditPrice ||
                                  cost.creditQtyRequested
                                ? cost.creditQtyRequested
                                : cost.creditNoteQty) || 0) * cost.pricePerUnit
                    }
                }
            }
            return total
        },
        projectBilled() {
            return this.project.totalInvoiced || 0
        },
        quoteTotalInvoice(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    cost.pricePerUnit *
                        (cost.creditNoteQty || cost.billedQty || 0),
                0
            )
        },
        quoteTotalCredit(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    cost.pricePerUnit *
                        ((this.invoice.status == 'closed'
                            ? cost.creditQty
                            : this.invoice.creditPrice ||
                              cost.creditQtyRequested
                            ? cost.creditQtyRequested
                            : cost.creditNoteQty) || 0),
                0
            )
        },
        quoteTotal(costs) {
            const invoiced = costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    cost.pricePerUnit *
                        (cost.creditNoteQty || cost.billedQty || 0),
                0
            )
            const credit = costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    cost.pricePerUnit *
                        ((this.invoice.status == 'closed'
                            ? cost.creditQty
                            : this.invoice.creditPrice ||
                              cost.creditQtyRequested
                            ? cost.creditQtyRequested
                            : cost.creditNoteQty) || 0),
                0
            )

            return invoiced - credit
        },
        quoteTotalQty(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator + (cost.creditNoteQty || cost.billedQty || 0),
                0
            )
        },
        creditTotalQty(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    (Number(
                        this.invoice.status == 'closed'
                            ? cost.creditQty
                            : this.invoice.creditPrice ||
                              cost.creditQtyRequested
                            ? cost.creditQtyRequested
                            : cost.creditNoteQty
                    ) || 0),
                0
            )
        },
        getQuoteName(quoteId) {
            const quote = this.project.quotes.find(q => q.id == quoteId)
            if (quote) {
                return quote.name
            }
            return quoteId
        },
        formatNumber(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.invoice.currency,
                maximumFractionDigits: this.invoice.currency == 'USD' ? 2 : 0,
            }).format(
                this.invoice.currency == 'USD' ? number : Math.ceil(number)
            )
        },
        formatNumber2(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.invoice.currency,
                maximumFractionDigits: this.invoice.currency == 'USD' ? 2 : 0,
            }).format(
                this.invoice.currency == 'USD' ? number : Math.ceil(number)
            )
        },
        onResize() {
            const {
                title: { clientHeight: titleHeight },
            } = this.$refs
            this.height = window.innerHeight - titleHeight - 80
        },
        close() {
            this.$emit('close')
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                await openFile(
                    file,
                    `${this.companyId}/projects/${this.project.id}/invoices`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        validateInvoiceItems(costs) {
            if (costs.length == 0) {
                return false
            }
            let response = false
            for (const cost of costs) {
                if (cost.creditNoteQty || cost.billedQty) {
                    response = true
                    break
                }
            }
            return response
        },
        maxValue(item) {
            const maxValue =
                (item.billedQty || item.creditNoteQty) - (item.creditQty || 0)
            if (Number(item.creditQtyRequested) > maxValue) {
                return `The value cannot be greater than ${maxValue}.`
            } else {
                return true
            }
        },
        validCredit() {
            let valid = false
            let validConcept = false
            if (this.invoice.concept) {
                const concept = this.getConcept(this.invoice.concept)
                if (concept) {
                    validConcept = concept.enabledValue
                }
            }
            if (!validConcept) {
                Object.keys(this.invoice.items).forEach(key => {
                    this.invoice.items[key].forEach(item => {
                        const maxValue =
                            (item.billedQty || item.creditNoteQty) -
                            (item.creditQty || 0)
                        if (
                            Number(item.creditQtyRequested) > maxValue ||
                            Number(item.creditQtyRequested) < 0
                        ) {
                            valid = false
                            return
                        }
                        if (
                            Number(item.creditQtyRequested) > 0 &&
                            Number(item.creditQtyRequested) <= maxValue
                        ) {
                            valid = true
                        }
                    })
                })
                let totalCredit = 0
                for (const quoteId of Object.keys(this.invoice.items)) {
                    for (const cost of this.invoice.items[quoteId]) {
                        totalCredit +=
                            (cost.creditQtyRequested || 0) * cost.pricePerUnit
                    }
                }
                if (this.changeValue) {
                    this.creditTotalPrice = totalCredit.toFixed(2)
                }
                if (Number(this.creditTotalPrice) <= 0) {
                    valid = false
                }
            } else {
                valid = this.creditTotalPrice > 0
            }

            return valid
        },

        creditAction() {
            if (this.invoice.status == 'credit') {
                this.openCloseInvoice = true
            } else if (this.invoice.status == 'billed') {
                this.creditReasonForm = true
            }
        },
        async requestCreditNote() {
            try {
                this.loading = true
                let requests = {}
                for (const quoteId of Object.keys(this.invoice.items)) {
                    if (this.invoice.items[quoteId].length > 0) {
                        let itemsRequested = this.invoice.items[quoteId].filter(
                            item =>
                                item.creditQtyRequested &&
                                item.creditQtyRequested > 0
                        )
                        if (itemsRequested.length > 0) {
                            requests[quoteId] = itemsRequested
                        }
                    }
                }
                const invoice = await API.requestCreditNote({
                    id: this.invoice.id,
                    projectId: this.invoice.projectId,
                    notes: this.rejectedNotes,
                    creditPrice: this.creditTotalPrice,
                    creditRequests: requests,
                })
                this.$emit('replaceInvoice', invoice)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        invoiceTotalCredit() {
            let totalCredit = 0
            if (this.invoice.status != 'closed') {
                if (this.invoice.totalCredit) {
                    totalCredit = this.invoice.totalCredit
                }
            }
            return totalCredit
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow-y: scroll;
}
</style>
